import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CartPopup from './CartPopup';
import ProductCard, { getProductDisplayName } from './ProductCard';

function App() {
  const [productsData, setProductsData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Alle categorieën');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [cart, setCart] = useState([]);
  const [isCartPopupVisible, setCartPopupVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://catalogus.gsmotorclub.nl/api/data');

        if (response.ok) {
          const data = await response.json();
          const productsWithCategories = data.map((product) => ({
            ...product,
            category: assignCategory(product.url),
            subcategory: assignSubcategory(product.url),
          }));
          setProductsData(productsWithCategories);
        } else {
          throw new Error(`Error fetching data. Status code: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const assignCategory = (url) => {
    if (urlIncludesCategory(url, ['462457', '462454', '672087', '1457158', '1574634', '1600808', '589535', '1772324', '1739220', '1729860', '1905942', '1785238', '386107', '411190', '1240099'])) {
      return 'Netwerk';
    } else if (urlIncludesCategory(url, ['1932292', '2007250', '1975150'])) {
      return 'Laptop';
    } else if (urlIncludesCategory(url, ['1867492', '1867494', '1620652', '2000630', '1764876', '1475628', '1615302', '1703462', '1507414', '1456502', '1746388', '1744144', '1744164', '1744176'])) {
      return 'SmartHome';
    } else {
      return 'Andere categorie';
    }
  };

  const assignSubcategory = (url) => {
    if (urlIncludesCategory(url, ['1772324', '1739220', '1785238'])) {
      return 'Router';
    } else if (urlIncludesCategory(url, ['462457', '462454', '1729860', '1905942'])) {
      return 'Wifi';
    } else if (urlIncludesCategory(url, ['672087', '1457158', '1574634', '1600808', '589535', '386107', '411190', '1240099'])) {
      return 'Switches';
    } else if (urlIncludesCategory(url, ['1867492', '1867494', '1620652', '2000630', '1764876'])) {
      return 'Camerasysteem';
    } else if (urlIncludesCategory(url, ['1475628', '1615302', '1703462'])) {
      return 'Audio';
    } else if (urlIncludesCategory(url, ['1507414', '1456502', '1746388', '1744144', '1744164', '1744176'])) {
      return 'Verlichting';
    } else {
      return '';
    }
  };

  const urlIncludesCategory = (url, categories) => {
    return categories.some(category => url.includes(category));
  };

  const filterProducts = () => {
    let filteredProducts = productsData;

    if (selectedCategory !== 'Alle categorieën') {
      filteredProducts = filteredProducts.filter((product) => product.category === selectedCategory);
    }

    if (selectedSubcategory !== '') {
      filteredProducts = filteredProducts.filter((product) => product.subcategory === selectedSubcategory);
    }

    return filteredProducts;
  };

  const addToCart = (product) => {
    const productWithPrices = {
      ...product,
      targetData: getTargetDataForProduct(product),
      productName: getProductDisplayName(product),
    };
    setCart((prevCart) => [...prevCart, productWithPrices]);
  };

  const getTargetDataForProduct = (product) => {
    const today = new Date().toISOString().split('T')[0];
    const targetData = product.dataset?.source?.filter((item) => today === item[0]);

    return targetData || [];
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const openCartPopup = () => {
    setCartPopupVisible(true);
  };

  const closeCartPopup = () => {
    setCartPopupVisible(false);
  };

  const categories = [
    { name: 'Alle categorieën', subcategories: [] },
    { name: 'Netwerk', subcategories: ['Router', 'Wifi', 'Switches', 'Netwerkkabels'] },
    { name: 'Laptop', subcategories: [''] },
    { name: 'SmartHome', subcategories: ['Camerasysteem', 'Audio', 'Verlichting'] },
    // Add more categories and subcategories if needed
  ];

  const handleCategoryChange = (category, subcategory) => {
    setSelectedCategory(category);
    setSelectedSubcategory(subcategory);
  };

  return (
    <div className={`container${isDarkMode ? ' dark-mode' : ''}`}>
      <div className="dark-mode-toggle">
       <label>Dark Mode:</label>
        <div className="iphone-switch" onClick={toggleDarkMode}>
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={toggleDarkMode}
          />
          <label htmlFor="darkModeToggle" className="iphone-switch-label"></label>
        </div>
      </div>

      <div className="filter-buttons">
        <label htmlFor="categoryDropdown">Categorie: </label>
        <select
          id="categoryDropdown"
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value, '')}
        >
          {categories.map((category) => (
            <option key={category.name} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>

        {selectedCategory && categories.find((c) => c.name === selectedCategory)?.subcategories.length > 0 && (
          <>
            <label htmlFor="subcategoryDropdown">Subcategorie: </label>
            <select
              id="subcategoryDropdown"
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
            >
              <option value="">Selecteer subcategorie</option>
              {categories.find((c) => c.name === selectedCategory)?.subcategories.map((subcategory) => (
                <option key={subcategory} value={subcategory}>
                  {subcategory}
                </option>
              ))}
            </select>
          </>
        )}
      </div>

      <div className="cart-container">
        <button className="cart-button" onClick={openCartPopup}>
          Winkelwagen ({cart.length})
        </button>
        <button className="clear-cart-button" onClick={() => setCart([])}>
          Leeg winkelwagen
        </button>

        {isCartPopupVisible && (
          <CartPopup cart={cart} onClose={closeCartPopup} />
        )}
      </div>

      <div className="row">
        {filterProducts().map((product) => (
          <div className="col-lg-4 col-sm-6 mb-30" key={product.id}>
            <ProductCard
              product={product}
              isSelected={selectedProductId === product.id}
              onClick={() => setSelectedProductId(product.id)}
              addToCart={() => addToCart(product)}
              isDarkMode={isDarkMode}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
