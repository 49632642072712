// CartPopup.js

import React from 'react';
import './CartPopup.css';
import ProductCard, { getProductDisplayName } from './ProductCard';

const CartPopup = ({ cart, onClose }) => {
  // Bereken de totaalprijs van de producten in de winkelwagen
  const totalPrices = cart.reduce(
    (totals, product) => {
      if (product.targetData && product.targetData.length > 0) {
        totals.avgPrice += product.targetData[0][2] * 1.1; // Voeg de gemiddelde prijs toe aan de totaalprijs met 10% verhoging
      }
      return totals;
    },
    { avgPrice: 0 }
  );

  return (
    <div className="cart-popup">
      <div className="cart-popup-content-wrapper">
        <div className="cart-popup-content">
          {/* Voeg een maximale hoogte en scroll toe aan de winkelwagen */}
          <span className="close-popup" onClick={onClose}>&times;</span>
          <h2>Winkelwagen</h2>
          <hr /> {/* Horizontale lijn */}

          {cart.map((product, index) => (
            <div className="cart-item" key={product.id}>
              <h4>{getProductDisplayName(product).productName}</h4>
              <span className="cart-item-price">
                {product.targetData && product.targetData.length > 0 ? (
                  <>
                    Gemiddelde Prijs: €{(product.targetData[0][2] * 1.1).toFixed(2)} <br />
                  </>
                ) : (
                  'Geen gegevens gevonden voor vandaag.'
                )}
              </span>
            </div>
          ))}
          {cart.length === 0 && <p>Je winkelwagen is leeg.</p>}
        </div>

        {/* Totaalprijs onderaan het popup venster, altijd zichtbaar zonder te scrollen */}
        <div className="total-prices">
          <hr /> {/* Horizontale lijn */}
          <h3>Totaal:</h3>
          <>
            Gemiddelde Prijs: €{totalPrices.avgPrice.toFixed(2)} <br />
          </>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
