import React from 'react';
import './ProductCard.css';

export const getProductDisplayName = (product) => {
  const { name, url, image } = product;

  const productData = [
    {
      pattern: '462457',
      name: 'Ubiquiti UniFi AP-AC-PRO',
      image: 'https://data.kommago.nl/img/products/large/76139_Ubiquiti-unifi-6-pro.webp',
      url: 'https://tweakers.net/pricewatch/462457/ubiquiti-unifi-ap-ac-pro-1-pack/specificaties/',
    },
    {
      pattern: '462454',
      name: 'Ubiquiti UniFi AP-AC-LITE',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-unifi-6-lite-access-point-1.webp',
      url: 'https://tweakers.net/pricewatch/462454/ubiquiti-unifi-ap-ac-lite-1-pack/specificaties/',
    },
    {
      pattern: '672087',
      name: 'UniFi Switch (8-poorts, 60W PoE)',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-us-8-60w-hi-res.webp',
      url: 'https://tweakers.net/pricewatch/672087/ubiquiti-unifi-switch-8-poorts-60w-poe/specificaties/',
    },
    {
      pattern: '1457158',
      name: 'Ubiquiti UniFi Switch Flex Mini',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-usw-mini-5-poorts-switch-1.webp',
      url: 'https://tweakers.net/pricewatch/1457158/ubiquiti-unifi-switch-flex-mini-per-stuk/specificaties/',
    },
    {
      pattern: '1574634',
      name: 'Ubiquiti UniFi Switch Lite 16 PoE',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-unifi-switch-lite-16-poe-usw-lite-16-poe-1.webp',
      url: 'https://tweakers.net/pricewatch/1574634/ubiquiti-unifi-switch-lite-16-poe/specificaties/',
    },
    {
      pattern: '1600808',
      name: 'Ubiquiti Unifi Switch Gen 2 (24-poorts)',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-usw-24-poe-gen2-switch-1.webp',
      url: 'https://tweakers.net/pricewatch/1600808/ubiquiti-unifi-switch-gen-2-24-poorts/specificaties/',
    },
    {
      pattern: '589535',
      name: 'Ubiquiti UniFi Switch (48-poorts)',
      image: 'https://data.kommago.nl/img/products/large/ubiquiti-us-48-switch-front.webp',
      url: 'https://tweakers.net/pricewatch/589535/ubiquiti-unifi-switch-48-poorts/specificaties/',
    },
    {
      pattern: '1772324',
      name: 'Ubiquiti UniFi Dream Router',
      image: 'https://data.kommago.nl/img/products/large/77485_Ubiquiti-UDR-UniFi-Dream-Router-1.webp',
      url: 'https://tweakers.net/pricewatch/1772324/ubiquiti-unifi-dream-router/specificaties/',
    },
    {
      pattern: '1739220',
      name: 'TP-Link Archer AX55',
      image: 'https://data.kommago.nl/img/products/large/77546_TP-Link-Archer-AX55-WiFI-6-router-1.webp',
      url: 'https://tweakers.net/pricewatch/1739220/tp-link-archer-ax55/specificaties/',
    },
    {
      pattern: '1729860',
      name: 'TP-Link Omada SDN EAP610',
      image: 'https://data.kommago.nl/img/products/large/74301_TP-Link-EAP610.webp',
      url: 'https://tweakers.net/pricewatch/1729860/tp-link-eap610/specificaties/',
    },
    {
      pattern: '1905942',
      name: 'TP-Link Omada SDN EAP613 Slim',
      image: 'https://data.kommago.nl/img/products/large/74301_TP-Link-EAP610.webp',
      url: 'https://tweakers.net/pricewatch/1905942/tp-link-eap613-per-stuk/specificaties/',
    },
    {
      pattern: '1785238',
      name: 'TP-Link Deco X50 (3-pack)',
      image: 'https://data.kommago.nl/img/products/large/66755_TP-Link-Deco-X60-1.webp',
      url: 'https://tweakers.net/pricewatch/1785238/tp-link-deco-x50-3-pack/specificaties/',
    },
    {
      pattern: '386107',
      name: 'TP-Link TL-SG108E',
      image: 'https://data.kommago.nl/img/products/large/37869_TP-Link-TL-SG108E-8-poorts-switch-1.webp',
      url: 'https://tweakers.net/pricewatch/386107/tp-link-tl-sg108e-gigabit-8-poorts/specificaties/',
    },
    {
      pattern: '411190',
      name: 'TP-Link TL-SG105E',
      image: 'https://data.kommago.nl/img/products/large/tp-link-tl-sg105e.webp',
      url: 'https://tweakers.net/pricewatch/411190/tp-link-tl-sg105e-gigabit-5-poorts/specificaties/',
    },
    {
      pattern: '1240099',
      name: 'TP-Link TL-SG116',
      image: 'https://data.kommago.nl/img/products/large/tp-link-tl-sg116.webp',
      url: 'https://tweakers.net/pricewatch/1240099/tp-link-tl-sg116/specificaties/',
    },
    {
      pattern: '1932292',
      name: 'Lenovo IdeaPad Slim 3',
      image: 'https://img.informatique.nl/750/24362301.jpg',
      url: 'https://tweakers.net/pricewatch/1932292/lenovo-ideapad-slim-3-15amn8-82xq0091mh/specificaties/',
    },
    {
      pattern: '2007250',
      name: 'Lenovo ThinkBook 16',
      image: 'https://assets.mmsrg.com/isr/166325/c1/-/ASSET_MMS_133904479?x=1800&y=1800&format=jpg&quality=80&sp=yes&strip=yes&ex=1800&ey=1800&align=center&resizesource&unsharp=1.5x1+0.7+0.02',
      url: 'https://tweakers.net/pricewatch/2007250/lenovo-thinkbook-16-g6-abp-21kk000umh/specificaties/',
    },
    {
      pattern: '1975150',
      name: 'HP Pavilion 15',
      image: 'https://azerty.nl/media/catalog/product/v/K/vKUNv2.c2a4721b2f8e53439716d145f4b37003-a657b31d.jpg?quality=80&bg-color=255,255,255&fit=bounds&height=700&width=700&canvas=700:700',
      url: 'https://tweakers.net/pricewatch/1975150/hp-pavilion-15-eg3080nd/specificaties/',
    },
    {
      pattern: '1867492',
      name: 'EufyCam 3',
      image: 'https://uk.eufy.com/cdn/shop/products/S330eufyCam_eufyCam3_Add-onCamera_1060x.png?v=1685368676',
      url: 'https://tweakers.net/pricewatch/1867492/eufy-s330-eufycam-eufycam-3-add-on-camera/specificaties/',
    },
    {
      pattern: '1867494',
      name: 'Eufy HomeBase 3 16GB',
      image: 'https://nl.eufy.com/cdn/shop/products/S380HomeBase_HomeBase3.png?v=1696433376&width=1600',
      url: 'https://tweakers.net/pricewatch/1867494/eufy-s380-homebase-homebase-3-16gb/specificaties/',
    },
    {
      pattern: '1620652',
      name: 'Eufy videodeurbel (Batterij)',
      image: 'https://crdms.images.consumerreports.org/prod/products/cr/models/404740-video-doorbells-eufy-video-doorbell-1080p-battery-powered-10023465.png',
      url: 'https://tweakers.net/pricewatch/1620652/eufy-video-doorbell-battery-uitbreiding/specificaties/',
    },
    {
      pattern: '2000630',
      name: 'Eufy E340 videodeurbel',
      image: 'https://nl.eufy.com/cdn/shop/products/T8214-ID-FrontView.png?v=1702979359&width=1600',
      url: 'https://tweakers.net/pricewatch/2000630/eufy-e340/specificaties/',
    },
    {
      pattern: '1764876',
      name: 'Eufy deurbel gong',
      image: 'https://www.robbshop.nl/thumbnail/6e/d5/fe/1671732394/EUF_DOORBELL_CHIME_image_1_1920x1920.jpg',
      url: 'https://tweakers.net/pricewatch/1764876/eufy-additional-doorbell-chime/specificaties/',
    },
    {
      pattern: '1475628',
      name: 'Google Nest Mini Gen 2',
      image: 'https://www.123led.nl/image/Google_Nest_Mini_Smart_Speaker_Assistant_%7C_Charcoal_LGO00018_big.jpg',
      url: 'https://tweakers.net/pricewatch/1475628/google-nest-mini-zwart/specificaties/',
    },
    {
      pattern: '1615302',
      name: 'Google Nest Audio',
      image: 'https://mobiel-product.imgix.net/f55ulah3oqsc7kijpsmkb84suq7e?w=540&h=540&dpr=2&trim=none&auto=compress%2Cformat',
      url: 'https://tweakers.net/pricewatch/1615302/google-nest-audio-zwart/specificaties/',
    },
    {
      pattern: '1703462',
      name: 'Google Nest Hub 2',
      image: 'https://mobiel-product.imgix.net/grlpn2embj3fnrw2i3s1rpvvfi6u?w=540&h=540&dpr=2&trim=none&auto=compress%2Cformat',
      url: 'https://tweakers.net/pricewatch/1703462/google-nest-hub-2-zwart/specificaties/',
    },
    {
      pattern: '1507414',
      name: 'Philips Hue Bridge 2.1',
      image: 'https://www.123led.nl/image/Philips_Hue_Bridge_LPH02743_big.jpg',
      url: 'https://tweakers.net/pricewatch/1507414/philips-hue-bridge-21/specificaties/',
    },
    {
      pattern: '1456502',
      name: 'Philips Hue smart plug',
      image: 'https://www.123led.nl/image/Philips_Hue_Smart_Plug_%7C_Max._2300W_%7C_Wit_NL_LPH02742_big.jpg',
      url: 'https://tweakers.net/pricewatch/1456502/philips-hue-smart-plug-eu/specificaties/',
    },
    {
      pattern: '1746388',
      name: 'Philips Hue White E27 1600 lumen',
      image: 'https://www.123led.nl/image/Philips_Hue_Smart_lamp_E27_%7C_White_%7C_1600_lumen_%7C_15.5W_LPH02730_big.jpg',
      url: 'https://tweakers.net/pricewatch/1746388/philips-hue-white-e27-1600-lumen/specificaties/',
    },
    {
      pattern: '1744144',
      name: 'Philips Hue White en Color Ambiance 1100 Lumen',
      image: 'https://www.123led.nl/image/Philips_Hue_Smart_lamp_E27_%7C_White_en_Color_Ambiance_%7C_1100_lumen_%7C_9W_LPH02707_big.jpg',
      url: 'https://tweakers.net/pricewatch/1744144/philips-hue-white-en-color-ambiance-1100-lumen-bluetooth-1-pack/specificaties/',
    },
    {
      pattern: '1744164',
      name: 'Philips Hue Starterkit E27 White Ambiance 1100 lumen',
      image: 'https://www.123led.nl/image/Philips_Hue_Starter_Kit_E27_%7C_White_Ambiance_%7C_3_lampen_1_dimmer_en_1_bridge_LPH02720_big.jpg',
      url: 'https://tweakers.net/pricewatch/1744164/philips-hue-starterkit-e27-white-ambiance-1100-lumen-3-lampen-bridge-en-dimmer-switch/specificaties/',
    },
    {
      pattern: '1744176',
      name: 'Philips Hue Starterkit E27 White & Color Ambiance 1100 lumen',
      image: 'https://www.123led.nl/image/Philips_Hue_Starter_Kit_E27_%7C_White_en_Color_Ambiance_%7C_3_lampen_1_dimmer_en_1_bridge_LPH02710_big.jpg',
      url: 'https://tweakers.net/pricewatch/1744176/philips-hue-starterkit-e27-white-en-color-ambiance-1100-lumen-3-lampen-bridge-en-dimmer-switch/specificaties/',
    },
  ];

  const matchedProduct = productData.find(item => url.includes(item.pattern)) || {};
  const { name: productName, image: productImage, url: productUrl } = matchedProduct;

  return {
    productName: productName || 'Onbekend product',
    productImage: productImage || image || '', // Use provided image or fallback to the product image in data
    productUrl: productUrl || '',
  };
};

const ProductCard = ({ product, isSelected, onClick, addToCart, isDarkMode }) => {
  const { dataset, category, subcategory } = product;
  const today = new Date().toISOString().split('T')[0];
  const targetData = dataset?.source?.filter(item => today === item[0]);
  const { productName, productImage, productUrl } = getProductDisplayName(product);
  const isClickable = Boolean(productName);

  const productNameElement = isClickable ? (
    <a href={productUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); window.open(productUrl, '_blank'); onClick(); }}>
      {productName}
    </a>
  ) : (
    <span>{productName || 'Onbekend product'}</span>
  );

  return (
    <div className={`product-card mx-auto mb-3 text-center ${isSelected ? 'selected' : ''} ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className={`product-thumb ${isDarkMode ? 'dark-mode' : ''}`} onClick={onClick}>
        <div className="img-container m-3">
          <img src={productImage} alt="Product Thumbnail" className="img-fluid" />
        </div>
      </div>
      <div className={`product-card-body ${isDarkMode ? 'dark-mode' : ''}`}>
        <h5 className={`product-title ${isDarkMode ? 'dark-mode' : ''}`}>{productNameElement}</h5>
        <p className={`product-category ${isDarkMode ? 'dark-mode' : ''}`}>
          Categorie: {category}
          {subcategory && (
            <>
              <br />
              Subcategorie: {subcategory}
            </>
          )}
        </p>
        <span className={`product-price ${isDarkMode ? 'dark-mode' : ''}`}>
          {targetData && targetData.length > 0 ? (
            <>
              Gemiddelde Prijs: €{(targetData[0][2] * 1.1).toFixed(2)} <br />
            </>
          ) : (
            'Geen gegevens gevonden voor vandaag.'
          )}
        </span>
        <button onClick={addToCart} className={`${isDarkMode ? 'dark-mode' : ''}`}>Voeg toe aan winkelwagen</button>
      </div>
    </div>
  );
};

export default ProductCard;